<template>
   <el-col class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>分析</el-breadcrumb-item>
            <el-breadcrumb-item>社交分析</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>

      <el-row class="secondarymarketing">
         <el-row class="funnel">
            <el-row class="title">使用分享功能用户数</el-row>
            <el-row class="row-data-box">
               <el-col :span="12">过去七日，按日，所有用户</el-col>
               <el-col :span="12">
                  <date-packer :datePacker="orderDateVala" @setDatePacker="getOrderDatePackera"></date-packer>
               </el-col>
            </el-row>
            <el-row id="numberofusers" style="width: 95%;height: 400px;" class="echa-box"></el-row>
         </el-row>
         <el-row class="funnel">
            <el-row class="title">不同城市分享用户分布</el-row>
            <el-row class="row-data-box">
               <el-col :span="12">过去七日，按日，所有用户</el-col>
               <el-col :span="12">
                  <date-packer :datePacker="orderDateVal" @setDatePacker="getOrderDatePacker"></date-packer>
               </el-col>
            </el-row>
            <el-row id="city" style="width: 95%; height: 400px;" class="echa-box"></el-row>
         </el-row>
      </el-row>

      <el-row class="reten-full">
         <el-row class="title">热门分享酒店</el-row>
         <el-row class="row-data-box">
            <el-col :span="12">过去七日。按日，所有用户</el-col>
            <el-col :span="12">
               <date-packer :datePacker="orderDateVals" @setDatePacker="getOrderDatePackers"></date-packer>
            </el-col>
         </el-row>
         <el-row id="popularhotels" class="echa-box" style="width: 90%; height: 400px; margin-top: 1rem; overflow: hidden;" ></el-row>
      </el-row>
   </el-col>

</template>

<script>
   import { mapState } from 'vuex'
   import {dateFactory} from "@/common/js/common";
   export default {
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.hotelId = this.hotelInfo.id
                  this.functionusers()
                  this.citySharing()
                  this.popularhotels()
               }
            },
            deep: true
         }
      },
      computed: {
         ...mapState(['hotelInfo'])
      },
      data(){
         return {
            orderDateVal:[],
            orderDateVals:[],
            orderDateVala:[],
            option : {
               xAxis: {
                  type: 'category',
                  data: ['2021/12/22', '2021/12/24', '2021/12/26', '2021/1/28']
               },
               yAxis: {
                  type: 'value'
               },
               series: [{
                  data: [80, 400, 400, 100,],
                  type: 'line',
                  smooth: true,
                  itemStyle: {
                     normal: {
                        color: '#1e80ff', //改变折线点的颜色
                        lineStyle: {
                           color: '#1e80ff' //改变折线颜色
                        }
                     }
                  },
               }]
            },
            options : {
               legend: {
                  data: ['北京市', '上海市', '城都市', '广州市', '西安市','杭州市']
               },
               xAxis: {
                  type: 'category',
                  data: ['2021/12/22', '2021/12/24', '2021/12/26', '2021/1/28']
               },
               yAxis: {
                  type: 'value'
               },
               series: [{
                  name: '北京市',
                  type: 'line',
                  stack: '总量',
                  data: [100, 100, 100, 100],
                  smooth: true,
                  itemStyle: {
                     normal: {
                        color: '#ffa438', //改变折线点的颜色
                        lineStyle: {
                           color: '#ffa438' //改变折线颜色
                        }
                     }
                  },
               },{
                  name: '上海市',
                  type: 'line',
                  stack: '总量',
                  data: [200, 200, 200, 200],
                  smooth: true,
                  itemStyle: {
                     normal: {
                        color: '#e94331', //改变折线点的颜色
                        lineStyle: {
                           color: '#e94331' //改变折线颜色
                        }
                     }
                  },
               },{
                  name: '城都市',
                  type: 'line',
                  stack: '总量',
                  data: [300, 300, 300, 300],
                  smooth: true,
                  itemStyle: {
                     normal: {
                        color: '#006fff', //改变折线点的颜色
                        lineStyle: {
                           color: '#006fff' //改变折线颜色
                        }
                     }
                  },
               },{
                  name: '广州市',
                  type: 'line',
                  stack: '总量',
                  data: [400, 400, 400, 400],
                  smooth: true,
                  itemStyle: {
                     normal: {
                        color: '#85daff', //改变折线点的颜色
                        lineStyle: {
                           color: '#85daff' //改变折线颜色
                        }
                     }
                  },
               },{
                  name: '西安市',
                  type: 'line',
                  stack: '总量',
                  data: [500, 500, 500, 500],
                  smooth: true,
                  itemStyle: {
                     normal: {
                        color: '#ddbffe', //改变折线点的颜色
                        lineStyle: {
                           color: '#ddbffe' //改变折线颜色
                        }
                     }
                  },
               },{
                  name: '杭州市',
                  type: 'line',
                  stack: '总量',
                  data: [600, 600, 600, 600],
                  smooth: true,
                  itemStyle: {
                     normal: {
                        color: '#6eca7f', //改变折线点的颜色
                        lineStyle: {
                           color: '#6eca7f' //改变折线颜色
                        }
                     }
                  },
               }
               ],
            },
            //热门分享酒店
            hotel : {
               color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
               tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                     type: 'cross',
                     label: {
                        backgroundColor: '#6a7985'
                     }
                  }
               },
               legend: {
                  data: ['安溪禧渼酒店', '观澜米阁酒店', '深圳大中华喜来登酒店', '佳兆业可域酒店深圳大鹏店', '南昌园中源酒店']
               },
               toolbox: {
                  feature: {
                     saveAsImage: {}
                  }
               },
               grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
               },
               xAxis: [
                  {
                     type: 'category',
                     boundaryGap: false,
                     data: ['2021.1.25', '2021.1.26', '2021.1.27', '2021.1.28', '2021.1.29', '2021.1.30', '2021.1.31']
                  }
               ],
               yAxis: [
                  {
                     type: 'value'
                  }
               ],
               series: [
                  {
                     name: '安溪禧渼酒店',
                     type: 'line',
                     stack: '总量',
                     smooth: true,
                     lineStyle: {
                        width: 0
                     },
                     showSymbol: false,
                     areaStyle: {
                        opacity: 0.8,
                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                           offset: 0,
                           color: 'rgba(128, 255, 165)'
                        }, {
                           offset: 1,
                           color: 'rgba(1, 191, 236)'
                        }])
                     },
                     emphasis: {
                        focus: 'series'
                     },
                     data: [140, 232, 101, 264, 90, 340, 250]
                  },
                  {
                     name: '观澜米阁酒店',
                     type: 'line',
                     stack: '总量',
                     smooth: true,
                     lineStyle: {
                        width: 0
                     },
                     showSymbol: false,
                     areaStyle: {
                        opacity: 0.8,
                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                           offset: 0,
                           color: 'rgba(0, 221, 255)'
                        }, {
                           offset: 1,
                           color: 'rgba(77, 119, 255)'
                        }])
                     },
                     emphasis: {
                        focus: 'series'
                     },
                     data: [120, 282, 111, 234, 220, 340, 310]
                  },
                  {
                     name: '深圳大中华喜来登酒店',
                     type: 'line',
                     stack: '总量',
                     smooth: true,
                     lineStyle: {
                        width: 0
                     },
                     showSymbol: false,
                     areaStyle: {
                        opacity: 0.8,
                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                           offset: 0,
                           color: 'rgba(55, 162, 255)'
                        }, {
                           offset: 1,
                           color: 'rgba(116, 21, 219)'
                        }])
                     },
                     emphasis: {
                        focus: 'series'
                     },
                     data: [320, 132, 201, 334, 190, 130, 220]
                  },
                  {
                     name: '佳兆业可域酒店深圳大鹏店',
                     type: 'line',
                     stack: '总量',
                     smooth: true,
                     lineStyle: {
                        width: 0
                     },
                     showSymbol: false,
                     areaStyle: {
                        opacity: 0.8,
                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                           offset: 0,
                           color: 'rgba(255, 0, 135)'
                        }, {
                           offset: 1,
                           color: 'rgba(135, 0, 157)'
                        }])
                     },
                     emphasis: {
                        focus: 'series'
                     },
                     data: [220, 402, 231, 134, 190, 230, 120]
                  },
                  {
                     name: '南昌园中源酒店',
                     type: 'line',
                     stack: '总量',
                     smooth: true,
                     lineStyle: {
                        width: 0
                     },
                     showSymbol: false,
                     label: {
                        show: true,
                        position: 'top'
                     },
                     areaStyle: {
                        opacity: 0.8,
                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                           offset: 0,
                           color: 'rgba(255, 191, 0)'
                        }, {
                           offset: 1,
                           color: 'rgba(224, 62, 76)'
                        }])
                     },
                     emphasis: {
                        focus: 'series'
                     },
                     data: [220, 302, 181, 234, 210, 290, 150]
                  }
               ]
            },
           HotelId:'',
         }
      },
     mounted() {
       this.hotelId = this.hotelInfo.id
        this.orderDateVala = this.orderDateVals = this.orderDateVal = [dateFactory.getDistanceAppoint(dateFactory.dateFormat(), '-', 7, false),dateFactory.dateFormat()]
       this.functionusers()
       this.citySharing()
       this.popularhotels()
        let myChart = this.$echarts.init(document.getElementById('numberofusers'));
        let cityChart = this.$echarts.init(document.getElementById('city'));
        let popularhotels = this.$echarts.init(document.getElementById('popularhotels'))
        window.onresize=function(){
           myChart.resize()
           cityChart.resize()
           popularhotels.resize()
        }
     },
      methods:{
         // 工单统计时间段过滤
         getOrderDatePacker(val) {
            this.orderDateVal = val;
            if (this.orderDateVal) {
               this.orderBeginTime = this.orderDateVal[0];
               this.orderEndTime = this.orderDateVal[1];
            } else {
               this.orderBeginTime = "";
               this.orderEndTime = "";
            }
            this.orderDateType = "";
            this.godan();
         },
         // 工单统计时间段过滤
         getOrderDatePackers(val) {
            this.orderDateVals = val;
            if (this.orderDateVals) {
               this.orderBeginTime = this.orderDateVals[0];
               this.orderEndTime = this.orderDateVals[1];
            } else {
               this.orderBeginTime = "";
               this.orderEndTime = "";
            }
            this.orderDateType = "";
            this.godan();
         },
         // 工单统计时间段过滤
         getOrderDatePackera(val) {
            this.orderDateVala = val;
            if (this.orderDateVala) {
               this.orderBeginTime = this.orderDateVala[0];
               this.orderEndTime = this.orderDateVala[1];
            } else {
               this.orderBeginTime = "";
               this.orderEndTime = "";
            }
            this.orderDateType = "";
            this.godan();
         },
         //参与各类促销活动用户分布  echart
         functionusers(){
            this.$echarts.init(document.getElementById('numberofusers')).setOption(this.option);
         },

         citySharing(){
            this.$echarts.init(document.getElementById('city')).setOption(this.options);
         },
         //热门分享酒店
         popularhotels(){
            this.$echarts.init(document.getElementById('popularhotels')).setOption(this.hotel);
         },
      }
   }
</script>

<style scoped lang="scss">
.secondarymarketing{
   display: flex;
   height: auto;
   margin: 0px 20px;
   margin-bottom: 20px;
   .funnel{
      width: 49%;
      background-color: white;
      box-shadow: 0px 0px 20px #dce7f9;
      .title{
         height: 50px;
         line-height: 50px;
         background: #f7f7f8;
         padding: 0px 20px;
      }
      .row-data-box{
         height: 50px;
         line-height: 50px;
         padding: 0px 20px;
      }
      .echa-box{
         padding: 0px 20px;
      }
   }
   .funnel:last-child{
      margin-left: 2%;
   }
}

.reten-full{
   margin: 0px 20px;
   background-color: white;
   box-shadow: 0px 0px 20px #dce7f9;
   .title{
      height: 50px;
      line-height: 50px;
      background: #f7f7f8;
      padding: 0px 20px;
   }
   .row-data-box{
      height: 50px;
      line-height: 50px;
      padding: 0px 20px;
   }
   .echa-box{
      padding: 0px 20px;
   }
}






.analysisfunction{width: 100%;height: 530px;margin-top: 2rem;
display: flex}
.analysisfunction .users{width: 800px; margin-left: 1%; height: 500px; float:left; box-shadow: 0px 0px 20px #dce7f9;}
.users{background-color:  white;}
.cityshare{background-color: white;}

.analysisfunction .cityshare{width: 800px; margin-right: 1%; height: 500px;float: right; box-shadow: 0px 0px 20px #dce7f9;}
.echa{width: 100%; height: 50px; margin-top: 1rem;}
.title{width: 100%; background: #f7f7f8; padding-left: 58px;height: 50px;line-height: 50px;}
.datas{float: left; width: 45%; font-size: 14px; margin-left: 3.7rem;}
.echart{float: right; width: 45%; text-align: right; margin-right: 1rem;}
.box{width: 98%; margin: auto; box-shadow: 0px 0px 20px #dce7f9; padding-bottom: 1rem;background-color: white;}
.boxs{width: 100%; height: 100%; margin-top: 1rem}
.boxs .titles{width: 48%; float: left; font-size: 14px; padding-left: 58px;}
.boxs .tiem{width: 48%; float: right; text-align: right;}
@media screen and (max-width: 1700px) {
  .main {
    width: 1650px;
  }
}
</style>